import React from "react"
import Layout from "src/component/layout/layout"
import SeminarPostList from "src/component/list/seminarPostList"
import { Seo } from "src/component/common/seo"

export default function Seminar({ data }) {
        React.useEffect(() => {
        let js_scroll = document.getElementsByClassName('js-scroll');
        const scroll = () => {
            for (let i = 0; i < js_scroll.length; i++) {
                if ((window.pageYOffset + (window.innerHeight / 1.5)) > (window.pageYOffset + js_scroll[i].getBoundingClientRect().top)) {
                    js_scroll[i].classList.add('is-active');
                }
            }
        };
        scroll();
        window.addEventListener('scroll', () => {
            scroll();
        })
    }, [])
    return (
        <>
            <Layout>
              
                <div className="resouses">
                    <div className="resouses__inner c-inner-primary">
                        <h1 className="resouses__title title-bold">SEMINAR</h1>
                            <div className="resouses__item__catchcopy title-bold-catchcopy c-pc js-scroll">
                                <div><div><span>セミナー</span></div></div>
                            </div>
                            <div className="resouses__item__catchcopy title-bold-catchcopy c-sp js-scroll">
                                <div><div><span>セミナー</span></div></div>
                            </div>
                    </div>

                <section className="resouses__articles">
                    <div className="resouses__articles__inner c-inner-primary">
                        <SeminarPostList/>
                    </div>
                </section>
              </div>
            
            </Layout>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="SEMINAR"
            description=""
            pathname=""
        />
    )
}